import axios from 'axios';

import { authService } from './auth';

const headers = function() { return { "Authorization": "Bearer " + authService.bearerToken, "Content-Type": "application/json" }; }  

export async function get(url: string) {
  var response;
  try {
    const getResponse = await axios({
      method: 'get',
      url: url,
      headers: headers()
    });
    if (getResponse && getResponse.data) { 
      response = getResponse.data;
    }
  } catch(error: any) {
    console.log(error);
    response = { error: error && error.response ? error.response.status : 0 };
  } finally {
    return response;
  }
}

export async function post(url: string, body: any) {
  var response;
  try {
    const postResponse = await axios({
      method: 'post',
      url: url,
      data: body,
      headers: headers()
    });
    if (postResponse && postResponse.data) { 
      response = postResponse.data;
    }
  } catch(error) {
    console.log(error);
  } finally {
    return response;
  }
}

export async function put(url: string, body: any) {
  var response;
  try {
    const putResponse = await axios({
      method: 'put',
      url: url,
      data: body,
      headers: headers()
    });
    if (putResponse && putResponse.data) { 
      response = putResponse.data;
    }
  } catch(error) {
    console.log(error);
  } finally {
    return response;
  }
}

export async function patch(url: string, patches: any) {
  var response;
  try {
    const patchResponse = await axios({
      method: 'patch',
      url: url,
      data: patches,
      headers: headers()
    });
    if (patchResponse && patchResponse.data) { 
      response = patchResponse.data;
    }
  } catch(error) {
    console.log(error);
  } finally {
    return response;
  }
}

export async function del(url: string) {
  var response;
  try {
    await axios({
      method: 'delete',
      url: url,
      headers: headers()
    });
    response = true;
  } catch(error) {
    console.log(error);
  } finally {
    return response;
  }
}