import { AppAction } from './actions';

import { 
  SET_LOADING,
  SET_INSTANCE,
  SET_FILTER,
  LOAD_MODULES,
  LOAD_ORDERS,

  ORDER_RECEIVED,
  KDS_STATE_UPDATED
} from "./actions";

import { IOrder } from '../interfaces/IOrder';

import { numberOfProductionOrders, getUpdatedOrderElementStates } from '../functions/actions/redux';

const initialState = {
  loading: false,
  instance: undefined,
  modules: [],
  orders: [],
  countInProduction: 0,

  filter: {
    minutesAhead: 60,
    modules: [],
    showRunOrders: true
  }
};

export default function reduce(state = initialState, action: AppAction) {
  switch (action.type) {
    
    case SET_LOADING: {
      const { loading } = action.payload;
      return {
        ...state,
        loading
      };
    }

    case SET_INSTANCE: {
      const { instance } = action.payload;

      return {
        ...state,
        instance,

        modules: [],
        orders: [],
        countInProduction: 0
      };
    }

    case SET_FILTER: {
      const { filter } = action.payload;
      return {
        ...state,
        filter
      };
    }

    case LOAD_MODULES: { 
      const { modules } = action.payload;
      return {
        ...state,
        modules
      };
    }

    case LOAD_ORDERS: { // GET ALL ORDER MODULES IF NONE ARE IN THE INSTANCE SETTINGS
      const { orders } = action.payload;

      const countInProduction = numberOfProductionOrders(orders);

      return {
        ...state,
        orders,
        countInProduction
      };
    }

    case ORDER_RECEIVED: {
      const { order } = action.payload;

      var orders: Array<IOrder> = state.orders ? state.orders.map(obj => obj) : [];
      if (!orders.find((o) => o.id === order.id)) {
        orders.unshift(order);
      }

      const countInProduction = numberOfProductionOrders(orders);
      
      return {
        ...state,
        orders, 
        countInProduction
      };
    }

    case KDS_STATE_UPDATED: { // Handle product/ingredient state update
      const { elementIds, orderId, updatedState, type } = action.payload;

      var updatedOrders: Array<IOrder> = state.orders ? state.orders.map(obj => obj) : [];

      // Find the order
      var orderIndex = updatedOrders.findIndex(o => o.id === orderId);

      if (orderIndex > -1) { 
        var order: IOrder = getUpdatedOrderElementStates(updatedOrders[orderIndex], elementIds, updatedState, type);
        updatedOrders[orderIndex] = order; 
      }

      const countInProduction = numberOfProductionOrders(updatedOrders);
      
      return {
        ...state,
        orders: updatedOrders,
        countInProduction
      };
    }

    default:
      return state;
  }
}