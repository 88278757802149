// Styling objects for Material UI components 

export const formControlStyles = {
    borderColor: '#dcdada',
    width: '100%',
    minWidth: 120,

    '& label.Mui-focused': {
        color: '#303030',
    },

    '& .MuiOutlinedInput-root': {
        '& fieldset': {
            borderColor: '#dcdada',
        },
        '&:hover fieldset': {
            borderColor: '#dcdada',
        },
        '&.Mui-focused fieldset': {
            borderColor: '#dcdada',
        },
        '&.Mui-disabled fieldset': {
            borderColor: '#dcdada',
        }
    }
}

export const selectStyles = {
    borderColor: '#dcdada',
    width: '100%',

    '& label.Mui-focused': {
        color: '#303030',
    },
    '& .MuiOutlinedInput-root': {
        '& fieldset': {
            borderColor: '#dcdada',
        },
        '&:hover fieldset': {
            borderColor: '#dcdada',
        },
        '&.Mui-focused fieldset': {
            borderColor: '#dcdada',
        },
        '&.Mui-disabled fieldset': {
            borderColor: '#dcdada',
        }
    }
}

export const autoCompleteStyles = {
    borderColor: '#dcdada',
    width: '100%',
    minWidth: '200px',

    '& label.Mui-focused': {
        color: '#303030',
    },
    '& .MuiOutlinedInput-root': {
        '& fieldset': {
            borderColor: '#dcdada',
        },
        '&:hover fieldset': {
            borderColor: '#dcdada',
        },
        '&.Mui-focused fieldset': {
            borderColor: '#dcdada',
        },
        '&.Mui-disabled fieldset': {
            borderColor: '#dcdada',
        }
    }
}

export const textFieldStyles = {
    root: {
        borderColor: '#dcdada',
        width: '100%',
    
        '& label.Mui-focused': {
          color: '#303030',
        },
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderColor: '#dcdada',
            },
            '&:hover fieldset': {
                borderColor: '#dcdada',
            },
            '&.Mui-focused fieldset': {
                borderColor: '#dcdada',
            },
            '&.Mui-disabled fieldset': {
                borderColor: '#dcdada',
            }
        },
    }
}

export const switchStyles = {
    '& .MuiSwitch-switchBase': {
        '&.Mui-checked': {
            color: '#292e42',

            '&:hover': {
                backgroundColor: 'rgba(107, 117, 250, 0.04)'
            },
            '& + .MuiSwitch-track': {
                backgroundColor: '#292e42',
            }
        },
        '&.Mui-checked + .MuiSwitch-track': {
            backgroundColor: '#292e42',
        },
    },
    checked: {},
    track: {},
}