// import { MessagePackHubProtocol } from '@microsoft/signalr-protocol-msgpack'

import { MiddlewareAPI, Dispatch, } from 'redux';
import { IOrder } from '../interfaces/IOrder';

import { authService } from '../functions/auth';

import {
    JsonHubProtocol,
    HubConnectionBuilder,
    LogLevel
} from '@microsoft/signalr';

import { 
    CONNECT_TO_WEBSOCKET,
    SET_INSTANCE,
    UPDATE_ELEMENT_STATE,
    UPDATE_INGREDIENT_STATE,
    ORDER_TO_CLOSED,

    orderReceived,
    kdsStateUpdated,
    loadOrders
} from "./actions";

import { kdsStates } from '../settings/app';
import { playNotificationSound } from '../functions/actions/orders';
import { updateConnectedInstance } from './thunks';

const isDev = process.env.NODE_ENV === 'development';

function setupSignalRConnection(url: string, setInstance: () => void) {

    const startSignalRConnection = async (connection: any, setInstance: () => void) => {
        try {
            await connection.start();
            setInstance();
        } catch (err) {
          setTimeout(() => startSignalRConnection(connection, setInstance), 5000);
        }
    };

    const options = {
        logMessageContent: isDev,
        logger: isDev ? LogLevel.Trace : LogLevel.Error,
        accessTokenFactory: () => authService.bearerToken
    };

    const connection = new HubConnectionBuilder()
        .withUrl(url, options)
        .withAutomaticReconnect()
        .withHubProtocol(new JsonHubProtocol())
        //.withHubProtocol(new MessagePackHubProtocol()) // JSON is default so using MessagePack will just enable both protocols 
        .build();

    connection.serverTimeoutInMilliseconds = 60000;

    connection.onclose(error => {
        let reload = window.confirm("Vi har desværre mistet forbindelsen. Genindlæs siden?");
        if (reload) {
            window.location.reload();
        }
    });

    startSignalRConnection(connection, setInstance);

    return connection;
}

const websocketMiddleware = (api: MiddlewareAPI) => {
    let connection: any;
    return (next: Dispatch) => async (action: any) => {

        // eslint-disable-next-line
        switch(action.type) {
            case CONNECT_TO_WEBSOCKET: {
                const instance = api.getState().instance ? api.getState().instance : action.payload.instance; // The currently chosen instance, in case it has changed 

                connection = setupSignalRConnection(action.payload.url, () => instance ? api.dispatch(updateConnectedInstance(instance)) : null);
                
                connection.onreconnected(() => {   
                    const updatedInstance = api.getState().instance;
                    if (updatedInstance) { // Setting the instance again if the connection was resat
                        connection.invoke("SetInstance", updatedInstance.id);
                        api.dispatch(loadOrders(updatedInstance.id));
                    }
                });

                connection.on("OrderReceived", (order: IOrder) => {
                    api.dispatch(orderReceived(order));

                    // Only playing if it matches the filter
                    // const orderMatchesFilter = filterOrderList([order], api.getState().filter);
                    // if (orderMatchesFilter && orderMatchesFilter.length === 1) {
                        playNotificationSound();
                    // }
                });

                connection.on("UpdateElementStates", (elementIds: Array<number>, orderId: number, updatedState: number) => {
                    api.dispatch(kdsStateUpdated(elementIds, orderId, updatedState, "product"));
                });

                connection.on("UpdateIngredientStates", (elementIds: Array<number>, orderId: number, updatedState: number) => {
                    api.dispatch(kdsStateUpdated(elementIds, orderId, updatedState, "ingredient"));
                });

                connection.on("OrderToClosed", (orderId: number) => {
                    api.dispatch(kdsStateUpdated([], orderId, 3, "order"));
                });

                break;
            }
            case SET_INSTANCE: {
                if (action.payload.instance) {
                    connection.invoke("SetInstance", action.payload.instance.id);
                } else {
                    const existingInstance = api.getState().instance;
                    if (existingInstance) { 
                        connection.invoke("UnsetInstance", existingInstance.id); 
                    }
                }

                break;
            }
            case UPDATE_ELEMENT_STATE: {

                const nextState = kdsStates.PRODUCTION;
                connection.invoke("UpdateElementStates", action.payload.elementIds, action.payload.orderId, nextState);

                break;
            }
            case UPDATE_INGREDIENT_STATE: {

                const nextState = kdsStates.PRODUCTION;
                connection.invoke("UpdateIngredientStates", action.payload.elementIds, action.payload.orderId, nextState);

                break;
            }
            case ORDER_TO_CLOSED: {

                connection.invoke("OrderToClosed", action.payload.orderId);

                break;
            }
        }

        return next(action);
    }
}
export default websocketMiddleware;