import React, { useMemo } from 'react';

import { useAppSelector, useAppDispatch } from '../../redux/hooks';
import { setFilter } from '../../redux/actions';

import MinutesAhead from './MinutesAhead';
import RunOrdersToggle from './RunOrdersToggle';
import Modules from './Modules';

import { IModule } from '../../interfaces/IModule';

export default function Filter() {
    const dispatch = useAppDispatch();

    const filter = useAppSelector((state) => state.filter);
    const instance = useAppSelector((state) => state.instance);
    const modules = useAppSelector((state) => state.modules);

    const modulesForInstance = useMemo(() => modules.filter((module: IModule) => instance.settings.modules.includes(module.id)), [instance, modules]);
    
    function handleFilterChange(type: string, value: any) {
        var newFilter = Object.assign({}, filter);

        switch (type) {
            case "minutesAhead": // Antal minutter frem
                newFilter.minutesAhead = value;
                break;
            case "modules": // Vælg modul 
                newFilter.modules = value;
                break;
            case "showRunOrders": // Vis kørte ordrer
                newFilter.showRunOrders = value;
                break;
        
            default:
                break;
        }
        dispatch(setFilter(newFilter));
    }

    return (
        <div className="header-filter-section">

            <RunOrdersToggle showRunOrders={filter.showRunOrders} handleFilterChange={handleFilterChange} />

            <MinutesAhead minutesAhead={filter.minutesAhead} handleFilterChange={handleFilterChange} />

            { modulesForInstance.length > 0 && 
                <Modules filterModuleIds={filter.modules} modules={modulesForInstance} handleFilterChange={handleFilterChange} />
            }
        
        </div>
    )
}