import React from 'react';

import { useAppDispatch } from '../../../redux/hooks';
import { updateIngredientState } from '../../../redux/actions';

import { kdsStates } from '../../../settings/app';

import IngredientList from './IngredientList';
import OrderHeader from '../OrderHeader/OrderHeader';
import OrderRunSummary from '../OrderRunSummary';
import EmptyList from './EmptyList';
import OrderComment from '../OrderComment';

import { summarizedIngredients } from '../../../functions/actions/orders';

import { IOrder } from '../../../interfaces/IOrder';
import { IIngredient } from '../../../interfaces/IIngredient';

export default function IngredientOrder(props: { order: IOrder, elementIdsToRun: Array<number>, clearChecked: () => void, checkedChange: (newValue: boolean, ids: Array<number>) => void }) {
    const { order, elementIdsToRun } = props;
    const { clearChecked } = props;

    const dispatch = useAppDispatch();
    
    var ingredientsToShow: Array<IIngredient> = summarizedIngredients(order.elements);

    var elementIdsInProduction = ingredientsToShow.filter(e => e.state && e.state.value === kdsStates.PRODUCTION).map(e => e.id);

    var disabled = !ingredientsToShow || ingredientsToShow.length === 0 || (elementIdsInProduction.length === ingredientsToShow.length);

    function handleRunClick() {
        var toRun;

        if (elementIdsToRun.length > 0) { // Run only the selected options
            toRun = elementIdsToRun;
            clearChecked();
        } else if (elementIdsToRun.length === 0) { // Run all options that aren't already in production

            var allElementIds: Array<number> = [];
            ingredientsToShow.forEach(function(element) {
                allElementIds = allElementIds.concat(element.ids ? element.ids : [element.id]);
            })

            toRun = allElementIds.filter(id => elementIdsInProduction.indexOf(id) === -1);
        }

        if (toRun && toRun.length) {
            dispatch(updateIngredientState(toRun, order.id));
        }
    }

    var showOrderRunSummary = () => elementIdsInProduction.length > 0 && !disabled;

    return (
        <div className={ disabled ? "order ingredient disabled" : "order ingredient" }>

            <OrderHeader 
                order={order}
                disabled={disabled}
                elementIdsToRun={elementIdsToRun}
                elementIdsInProduction={elementIdsInProduction}
                handleRunClick={handleRunClick}
            />

            { ingredientsToShow && ingredientsToShow.length > 0 && 
                <IngredientList 
                    ingredients={ingredientsToShow}
                    {...props}
                />
            }

            { (!ingredientsToShow || ingredientsToShow.length === 0) && 
                <EmptyList orderId={order.id} />
            }

            { ingredientsToShow && ingredientsToShow.length > 0 && <OrderComment comment={order.comment} /> }

            { showOrderRunSummary() && 
                <OrderRunSummary 
                    numberOfElements={ingredientsToShow.length} 
                    numberOfElementsInProduction={elementIdsInProduction.length} 
                />
            }

        </div>
    )
}