import React, { useState } from 'react';
import { useAppDispatch } from '../../../redux/hooks';
import { useTranslation } from 'react-i18next';
import { orderToClosed } from '../../../redux/actions';

export default function EmptyList(props: { orderId: number }) {
    const { orderId } = props;

    const { t } = useTranslation();
    const dispatch = useAppDispatch();

    var [showConfirmationButtons, setShowConfirmationButton] = useState(false);

    function removeOrder() {
        dispatch(orderToClosed(orderId))
    }

    return (
        <div className="empty-ingredient-list">
            { !showConfirmationButtons &&
                <p className="empty-ingredient-list-text">{t("orders:EmptyIngredientList")}</p>
            }

            { !showConfirmationButtons && 
                <button className="button remove-empty-order-button" onClick={() => setShowConfirmationButton(true)}>{t("common:Remove")}</button>
            }

            { showConfirmationButtons && 
                <div className="confirmation-button-section">
                    <p className="confirmation-text">{t("common:AreYouSure")}</p>
                    <div className="confirmation-buttons">
                        <button className="button remove-empty-order-confirmation-button" onClick={removeOrder}>{t("common:Yes")}</button>
                        <button className="button remove-empty-order-reject-button" onClick={() => setShowConfirmationButton(false)}>{t("common:No")}</button>
                    </div>
                </div>
            }

        </div>
    )
}