import React, { useState } from 'react';

import { useAppSelector } from '../../redux/hooks';

import { IOrder } from '../../interfaces/IOrder';

import ProductOrder from './Product/ProductOrder';
import IngredientOrder from './Ingredient/IngredientOrder';

export default function Order(props: { order: IOrder }) {
    const { order } = props;
    const { instance } = useAppSelector((state) => state); 

    const viewType = instance && instance.settings.kdsViewOption === 0 ? "product": "ingredient";
    
    var [elementIdsToRun, setElementIdsToRun] = useState<Array<number>>([]); // Elements marked to run in this order

    function orderElementCheckedChange(newValue: boolean, ids: Array<number>) {
        var updatedList: Array<number> = elementIdsToRun.map(obj => obj);

        if (newValue === true) { // Add to list
            updatedList = updatedList.concat(ids);
        } else { // Remove from list 
            updatedList = updatedList.filter(elementId => ids.indexOf(elementId) === -1);
        }

        setElementIdsToRun(updatedList);
    }

    return (
        <div className="order-container list-item">
            
            { viewType === "product" && 
                <ProductOrder 
                    key={order.id} 
                    order={order} 
                    elementIdsToRun={elementIdsToRun}

                    checkedChange={orderElementCheckedChange}
                    clearChecked={() => setElementIdsToRun([])}
                />
            } 

            { viewType === "ingredient" && 
                <IngredientOrder
                    key={order.id} 
                    order={order} 
                    elementIdsToRun={elementIdsToRun}

                    checkedChange={orderElementCheckedChange}
                    clearChecked={() => setElementIdsToRun([])}
                />
            } 

        </div>
    )
}