import React from 'react';

import { useAppDispatch } from '../../../redux/hooks';
import { updateElementState } from '../../../redux/actions';

import { getElementIds } from '../../../functions/actions/orders';

import OrderHeader from '../OrderHeader/OrderHeader';
import ProductList from './ProductList/ProductList';
import OrderRunSummary from '../OrderRunSummary';
import OrderComment from '../OrderComment';

import { IElement } from '../../../interfaces/IElement';
import { IOrder } from '../../../interfaces/IOrder';
import { kdsStates } from '../../../settings/app';

export default function ProductOrder(props: { order: IOrder, elementIdsToRun: Array<number>, clearChecked: () => void, checkedChange: (newValue: boolean, ids: Array<number>) => void }) {
    const { order, elementIdsToRun } = props;
    const { clearChecked } = props;

    const dispatch = useAppDispatch();
    
    var productsToShow: Array<IElement> = order.elements; // Only top elements (products) 

    var elementIds = getElementIds(productsToShow); // Looping all elements, including children 
    var elementIdsInQueue = elementIds.inQueue; 
    var elementIdsInProduction = elementIds.inProduction; 

    var disabled = order.stateId !== kdsStates.QUEUE; 

    function handleRunClick() {
        var toRun;

        if (elementIdsToRun.length > 0) { // Run only the selected options
            toRun = elementIdsToRun;
            clearChecked();
        } else if (elementIdsToRun.length === 0) { // Run all options that aren't already in production
            toRun = elementIdsInQueue; 
        }

        if (toRun && toRun.length) {
            dispatch(updateElementState(toRun, order.id));
        }
    }

    var showOrderRunSummary = () => elementIdsInProduction.length > 0 && !disabled;

    return (
        <div className={ disabled ? "order product disabled" : "order product" }>

            <OrderHeader 
                order={order}
                disabled={disabled}
                elementIdsToRun={elementIdsToRun}
                elementIdsInProduction={elementIdsInProduction}
                handleRunClick={handleRunClick}
            />

            <ProductList 
                elements={productsToShow} 
                {...props}
            />

            <OrderComment comment={order.comment} />

            { showOrderRunSummary() && 
                <OrderRunSummary 
                    numberOfElements={elementIds.ids.length} 
                    numberOfElementsInProduction={elementIdsInProduction.length} 
                />
            }

        </div>
    )
}