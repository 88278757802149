import i18n from '../../i18n';

import { get } from '../api';
import { kds_url, settings_url } from '../../settings/app';

export async function setupDefaultLanguage() {

    try {
        const languageSettings = await getLanguageSettings();

        if (languageSettings && languageSettings.defaultLanguage) { // Only handling one language per account for now 
            const languageToUse = languageSettings.defaultLanguage;
            if (languageToUse && languageToUse !== "da-DK" && i18n.language !== languageToUse) {
                i18n.changeLanguage(languageToUse);
            }
        }
    } catch(error) {
        console.log(error);
    }
}

export async function getLanguageSettings() {
    return await get(settings_url + "/Language");
}

export async function getKDSInstances() {
    return await get(kds_url + "/instance");
}