import React from 'react';

import Product from './Product';

import { IElement } from '../../../../interfaces/IElement';

// Showing the list of order elements as products/choices or as summarized ingredients 
export default function ProductList(props: { elements: Array<IElement>, elementIdsToRun: Array<number>, checkedChange: (value: boolean, ids: Array<number>) => void }) { 
    const { elements } = props;

    const sortedElements = elements ? elements.sort((a, b) => a.externalName < b.externalName ? -1 : 1) : []; // Sorting alphabetically until we have sortOrder 

    return (
        <div className="order-body">

            { sortedElements.map(function(element) {
                return <Product 
                            key={element.id} 
                            element={element} 
                            {...props}
                        />
            })}

        </div>
    )
}