import { UserManager } from "oidc-client";

import jwt from "jwt-decode";
import { auth_url } from "../settings/app";

export var authConfig = {
  authority: auth_url,
  client_id: "applogin", // "applogindev" for testing 
  redirect_uri: window.location.origin + '/callback',
  post_logout_redirect_uri: window.location.origin,

  response_type: "code",
  scope: "openid profile catalog warehouse productionunit order mail setting capacity discount giftcard orderstatus module checkout product menu kds",

  loadUserInfo: true,

  // Silent renew will get a new access_token via an iframe 
  silent_redirect_uri: window.location.origin + '/silent',
  automaticSilentRenew: true,
  silentRequestTimeout: 50000,

  monitorAnonymousSession: true,

  // Revoke (reference) access tokens at logout time
  revokeAccessTokenOnSignout: true,

  // This will allow all the OIDC protocol claims to be visible in the window (normally won't use this in a client app)
  //filterProtocolClaims: false
};

class AuthService {
  UserManager: any;
  bearerToken: string;
  customer: string;

  constructor() {
    this.UserManager = undefined; // Until we initialize it 
    this.bearerToken = "";
    this.customer = "";
  }

  async initAuthService() {
    this.UserManager = new UserManager(authConfig);
    const self = this;

    this.UserManager.events.addUserLoaded((user: { access_token: string }) => { // Triggers after each signin and silent refresh of token
      self.updateBearerToken(user.access_token);
    });
    this.UserManager.events.addSilentRenewError(() => { // Silent signing failed, redirecting to signin page 
      self.UserManager.signinRedirect();
    });

    this.UserManager.events.addAccessTokenExpired(() => { // Triggers 60 seconds before the token expires 
      this.signinSilent();
    });

    return this.UserManager;
  }

  updateBearerToken(token: string) {
    this.bearerToken = token; 
    
    const decoded_token: { organisationId: string } = jwt(token);
    if (decoded_token && decoded_token.organisationId) {
      if (this.customer && this.customer !== decoded_token.organisationId) { // We did a silent refresh and the organization changed - redirect to login 
        this.UserManager.signinRedirect();
      } else {
        this.customer = decoded_token.organisationId;
      }
    }
  }

  getUser = async () => { // Fetching the user on page load to check if we're logged in and get the token 
    const user = await this.UserManager.getUser();
    if (!user) {
      this.UserManager.signinRedirect();
    } else {
      this.updateBearerToken(user.access_token);
    }
    return user;
  };

  parseJwt = (token: string) => { // Parsing the JWT token for the customer name etc.
    const base64Url = token.split(".")[1];
    const base64 = base64Url.replace("-", "+").replace("_", "/");
    return JSON.parse(window.atob(base64));
  };


  signinRedirect = () => {
    localStorage.setItem("redirectUri", window.location.pathname);
    this.UserManager.signinRedirect({});
  };

  signinSilent = () => {
    const self = this;
    this.UserManager.signinSilent().then(() => { // Returns undefined but signs us in 
    }).catch((err: any) => {
      console.log(err);
      
      // Silent signing failed, redirect to signin page 
      self.UserManager.signinRedirect(); 
    });
  };

  signinSilentCallback = () => { 
    if (this.UserManager) {
      this.UserManager.signinSilentCallback().then(() => { // Returns undefined
        console.log("signinSilentCallback success");
      }).catch((err: any) => {
        console.log("signingSilentCallback error");
        console.log(err);
      });
    }
  };
}
export var authService = new AuthService();

export function redirectAuth(authenticated: boolean) {
  if (authenticated) {
    authService.UserManager.signoutRedirect();
  } else {
    authService.UserManager.signinRedirect();
  }
}