// KDS states
export const kdsStates = { 
    "QUEUE": 0,
    "PRODUCTION": 1,
    "READY": 2,
    "COMPLETED": 3
}

// Letting ci and staging sites use the equivalent apis 
var path = "";
if (window.location.href.includes(".ci.")) {
    path = "ci.";
}
if (window.location.href.includes(".staging.")) {
    path = "staging.";
}


export const auth_url = "https://" + (process.env.REACT_APP_AUTH_URI || `auth.${path}crunchy.ninja`);

export const module_url = "https://" + (process.env.REACT_APP_MODULE_URI || `module.${path}crunchy.ninja/api`);
export const kds_url = "https://" + (process.env.REACT_APP_KDS_URI || `kds.${path}crunchy.ninja/api`);
export const settings_url = "https://" + (process.env.REACT_APP_CUSTOMER_SETTING_URI || `customersetting.${path}crunchy.ninja/api`);

export const kds_websocket = "https://" + (process.env.REACT_APP_KDS_WEBSOCKET_URI || `kds.${path}crunchy.ninja/kds`);
