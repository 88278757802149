import React from 'react';
import { useTranslation } from 'react-i18next';

export default function OrderComment({ comment }: { comment?: string | null }) {
    const { t } = useTranslation();

    if (!comment) {
        return <></>;
    }

    return (
        <div className="order-comment">
            <p className="order-comment-title">{t("orders:Comment")}:</p>
            <p className="order-comment-text">{comment}</p>
        </div>
    )
}