import React from 'react';

export default function Spinner() {

    return (

        <div className="spinner-container">
            <div className="spinner"></div>
        </div>
      );
}