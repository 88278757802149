import moment from "moment";

import { get } from "../api";
import { module_url } from "../../settings/app";

import { IOrder } from '../../interfaces/IOrder';
import { IElement } from '../../interfaces/IElement';
import { IElementState } from "../../interfaces/IElementState";

import { kdsStates } from '../../settings/app';
import { addStateToOrders, getElementIds } from "./orders";

// Filtering for the same as production KDS (not the order KDS filter etc.) for the number of production orders to show in the header 
const getProductionOrders = (orders: Array<IOrder>) => addStateToOrders(orders).filter((o: IOrder) => o.stateId === kdsStates.PRODUCTION && moment(o.orderReadyTime).isSame(moment(), "day"));
export const numberOfProductionOrders = (orders: Array<IOrder>) => getProductionOrders(orders).length;

export async function getModules() {
    var modules = [];
    
    try {
        modules = await get(module_url + "/module/modules");
    } catch(error) {
        console.log(error);
    } finally {
        return modules;
    }
}

export let getUpdatedOrderElementStates = function f(originalOrder: IOrder, elementIds: Array<number>, updatedState: number, type: string) { // Updating the element states inside the order
    var order: IOrder = Object.assign({}, originalOrder);

    function updateProductElementStates(elements: Array<IElement>, elementId: number, updatedState: number) {
        elements.forEach(element => {
            if(element.id === elementId) {
                var elementState: IElementState = element.state ? element.state : {};
                elementState.value = updatedState;
                element.state = elementState;
            } else {
                if (element.children) { updateProductElementStates(element.children, elementId, updatedState); }
            }
        });
    }

    function updateIngredientStates(elements: Array<IElement>, elementId: number, updatedState: number) {
        elements.forEach(element => {
            var ingredientIndexFound = element.ingredients ? element.ingredients.findIndex(ingredient => ingredient.id === elementId) : -1;

            if(ingredientIndexFound > -1) {
                var ingredient = element.ingredients[ingredientIndexFound];
                var elementState: IElementState = ingredient.state ? ingredient.state : {};
                elementState.value = updatedState;
                ingredient.state = elementState;
                element.ingredients[ingredientIndexFound] = ingredient;
            } else {
                if (element.children) { updateIngredientStates(element.children, elementId, updatedState); }
            }
        });
    }

    var updatedElementList = order.elements.map(e => e);
    var elementIdsToUpdate = elementIds.map(e => e);

    if (type === "order") { // If it's an order, we don't have the element ids. Get them before looping 
        elementIdsToUpdate = getElementIds(order.elements).ids;
    }

    elementIdsToUpdate.forEach(function(elementId) {
        if (type === "product" || type === "order") {
            updateProductElementStates(updatedElementList, elementId, updatedState);
        } else if (type === "ingredient") {
            updateIngredientStates(updatedElementList, elementId, updatedState);
        }
    });

    order.elements = updatedElementList;
    order.lastModifiedUtc = moment().format();
    return order;
}