import React from 'react';
import { useTranslation } from 'react-i18next';

import { IInstance } from '../interfaces/IInstance';

export default function InstanceSelector(props: { instances: Array<IInstance>, handleInstanceClick: (instance: IInstance) => void }) {
    const { instances } = props;
    const { handleInstanceClick } = props;

    const { t } = useTranslation();

    const name = (instance: IInstance) => instance && instance.settings && instance.settings.name ? instance.settings.name : t("common:DefaultInstanceName")

    return (
        <div className="instance-selector">

            { instances && instances.map(function(instance) {
                return <div key={instance.id} className="instance-container" onClick={() => handleInstanceClick(instance)}>
                            <p className="instance-name">{name(instance)}</p>
                        </div>
            })}

        </div>
    )
}