import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import moment from 'moment';

import { getTimestampFromMilliseconds } from '../../../functions/actions/orders';

export default function Counter(props: { orderTimeUtc: string }) {
    const { orderTimeUtc } = props;

    const { t } = useTranslation();

    const orderTimeMoment = moment.utc(orderTimeUtc); // Getting the moment once to use for future calculations

    const ms = moment().diff(orderTimeMoment); // Calculating the timestamp in milliseconds on load
    var [milliseconds, setMilliseconds] = useState(ms);

    useEffect(() => {
        const timer = setInterval(() => {
            const timeDiffInMilliseconds = moment().diff(orderTimeMoment); // The difference between now and ordertime in milliseconds
            setMilliseconds(timeDiffInMilliseconds); 
        }, 1000);
        return () => clearInterval(timer);
    }, [orderTimeMoment]);

    return (
        <span className="order-time-counter">
            { getTimestampFromMilliseconds(milliseconds) } 
            { milliseconds < 180000 && <span className="new-order-highlight">{t("orders:New")}</span> }
        </span>
    )
}