import React from 'react';

import Checkbox from '../../../Checkbox';

import { kdsStates } from '../../../../settings/app';

import { IElement } from '../../../../interfaces/IElement';

export default function ElementChildChild(props: { elementChildChild: IElement, elementIdsToRun: Array<number>, checkedChange: (value: boolean, ids: Array<number>) => void }) { // A list of choices for the element
    const { elementChildChild, elementIdsToRun } = props;
    const { checkedChange } = props;
    
    var isChecked = elementIdsToRun.indexOf(elementChildChild.id) > -1;
    var isInProduction = elementChildChild.state && elementChildChild.state.value === kdsStates.PRODUCTION ? true : false; // To overrule the checked state when all elements are run at once 

    function handleOrderElementClick(event: React.MouseEvent, elementChildChild: IElement) {
        event.stopPropagation();

        var updatedValue = !isChecked;
        checkedChange(updatedValue, [elementChildChild.id]);
    }

    return (
        <div className="element-child-child-container" onClick={(event) => handleOrderElementClick(event, elementChildChild)}>
            <Checkbox isChecked={isChecked || isInProduction} isActive={!elementChildChild.state || elementChildChild.state.value === kdsStates.QUEUE} />
            <p key={elementChildChild.id} className="element-child-child">
                {elementChildChild.amount} x {elementChildChild.externalName}
            </p>
        </div>
    )
}