import React from 'react';
import { useTranslation } from 'react-i18next';

import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';

import { styled } from '@mui/material/styles';

import { switchStyles } from '../../functions/mui';

const StyledSwitch = styled(Switch)(switchStyles);

export default function RunOrdersToggle(props: { showRunOrders: boolean, handleFilterChange: (type: string, value: boolean) => void }) {
    const { showRunOrders } = props;
    const { handleFilterChange } = props;

    const { t } = useTranslation();

    return (
        <div className="input-container">
            <FormControlLabel 
                label={t<string>("header:ShowProductionOrders")} 
                control={
                    <StyledSwitch 
                        checked={showRunOrders} 
                        onChange={(event) => handleFilterChange("showRunOrders", event.target.checked)} 
                    />
                }
            />
        </div>
    )
}