

import i18n from "i18next";
import { initReactI18next } from "react-i18next";

// With namespaces

// DA
import commonDA from './languages/da/common.json';
import headerDA from './languages/da/header.json';
import ordersDA from './languages/da/orders.json';

// EN
import commonEN from './languages/en/common.json';
import headerEN from './languages/en/header.json';
import ordersEN from './languages/en/orders.json';

const resources = {
  "da": { 
    "common": commonDA,
    "header": headerDA,
    "orders": ordersDA
  },
  "en": { 
    "common": commonEN,
    "header": headerEN,
    "orders": ordersEN
  }
}

i18n.use(initReactI18next).init({
    resources: resources,

    lng: "da",
    fallbackLng: "da",

    ns: ["common", "header", "orders"],
    defaultNS: "common",

    supportedLngs: ["da", "en"],

    debug: true,
    keySeparator: false, // we use content as keys

    interpolation: {
      escapeValue: false, // not needed for react
      formatSeparator: ","
    },

    react: {
      useSuspense: true
    }
});

export default i18n;