import React, { useEffect } from 'react';

import { authService } from '../functions/auth';

export default function Silent() {

  useEffect(() => {

    authService.initAuthService().then(function() { // Re-initializing the oidc manager
      authService.signinSilentCallback();
    }).catch(function(error) {
      console.log(error);
    });

  }, []);

  return (
    <div className="silent"></div>
  )

}