import React from 'react';
import { useTranslation } from 'react-i18next';

import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

import { styled } from '@mui/material/styles';

import { formControlStyles } from '../../functions/mui';

const StyledFormControl = styled(FormControl)(formControlStyles);

export default function MinutesAhead(props: { minutesAhead: number, handleFilterChange: (type: string, value: number | string) => void }) {
    const { minutesAhead } = props;
    const { handleFilterChange } = props;

    const { t } = useTranslation();

    const minutesAheadOptions = [15, 30, 60, 120, 180, 240, 300, 360, 420, 480, 540, 600, 660, 720, 780, 840, 900];

    const minutesAheadLabel = (value: number) => value/60 < 1 ? value + " " + t("header:Minutes") : value/60 === 1 ? value/60 + " " + t("header:Hour") : value/60 + " " + t("header:Hours"); // Returning a label in hours or minutes

    return (
        <div className="time-selector-section filter-item">
            <StyledFormControl fullWidth>
                <InputLabel id="hour-select-label">{t("header:OrdersUntilTime")}</InputLabel>
                <Select
                    labelId="hour-select-label"
                    id="hour-select-label"
                    label={t("header:OrdersUntilTime")}

                    value={minutesAhead}
                    onChange={(event) => handleFilterChange("minutesAhead", event.target.value)}
                >
                    { minutesAheadOptions.map(function(option) {
                        return <MenuItem key={option} value={option}>{minutesAheadLabel(option)}</MenuItem>
                    })}
                </Select>
            </StyledFormControl>
        </div>
    )
}