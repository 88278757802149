import React from 'react';
import { useTranslation } from 'react-i18next';

export default function OrderRunSummary(props: { numberOfElements: number, numberOfElementsInProduction: number }) {
    const { numberOfElements, numberOfElementsInProduction } = props;

    const { t } = useTranslation();

    return (
        <div className="order-run-summary">
            <p className="order-run-summary-text">
                {numberOfElementsInProduction} {t("common:OutOf")} {numberOfElements} {t("orders:RunPastTense")}
            </p>
        </div>
    )
}