import React from 'react';
import { useTranslation } from 'react-i18next';

import { useAppSelector } from '../../../redux/hooks';

import moment from 'moment';

import Counter from './Counter';

import { moduleSettings } from '../../../settings/modules';
import { IOrder } from '../../../interfaces/IOrder';
import { IModule } from '../../../interfaces/IModule';

export default function OrderHeader(props: { order: IOrder, disabled: boolean, elementIdsToRun: Array<number>, elementIdsInProduction: Array<number>, handleRunClick: () => void }) {
    const { 
        order, 
        disabled,
        elementIdsToRun, // Elements marked to run in this order
        elementIdsInProduction // Elements in this order that have already run
    } = props;
    const { handleRunClick } = props;

    const { t } = useTranslation();

    const { modules } = useAppSelector((state) => state); 

    const module = modules.find((m: IModule) => m.id === order.moduleId);
    const settings = moduleSettings[module ? module.type : "default"] || moduleSettings["default"];

    // Showing the appropriate button text 
    var buttonTitle = t("orders:RunAll");
    if (elementIdsInProduction.length > 0) { buttonTitle = t("orders:RunRest"); }
    if (elementIdsToRun.length > 0) { buttonTitle = t("orders:Run"); }

    return (
        <div className="order-header-section">

            <div className="order-header">
                <div className="order-header-left">
                    <p className="order-info-text">
                        
                        { settings.orderReadyTime && <span className="order-ready-time">{moment(order.orderReadyTime).format("HH:mm")} </span> }
                        
                        { settings.customerName && order.patron && order.patron.name && <span className="order-name">{order.patron.name} </span> }

                        { settings.table && order.table && <span className="order-table">{order.table.name}</span> }

                        { settings.counter && <Counter orderTimeUtc={order.orderTimeUtc} /> }

                    </p>
                </div>
                <div className="order-header-right">
                    { !disabled && <button className="button" onClick={handleRunClick}>{buttonTitle}</button> }
                </div>
            </div>

            <div className="order-receipt-number-container">
                <p className="order-receipt-number">#{order.receiptId}</p>
            </div>

        </div>
    )
}