import { IModuleSettings } from "../interfaces/IModuleSettings";

// A list of allowed modules 
export const moduleTypesList = [ 
    { "name": "takeaway", "type": 1, "translationKey": "common:Takeaway" }, 
    { "name": "clickandcollect", "type": 0, "translationKey": "common:ClickAndCollect" }, 
    { "name": "orderattable", "type": 2, "translationKey": "common:OrderAtTable" }, 
    { "name": "roomservice", "type": 7, "translationKey": "common:Roomservice" }, 
    { "name": "fasttrack", "type": 6, "translationKey": "common:Fasttrack" }
];

// View settings for each module
export const moduleSettings: IModuleSettings = {
    "default": {
        "counter": true,
        "table": false,
        "customerName": true,
        "orderReadyTime": false
    },
    0: {
        "counter": true,
        "table": false,
        "customerName": true,
        "orderReadyTime": false
    },
    1: {
        "counter": false,
        "table": false,
        "customerName": true,
        "orderReadyTime": true
    },
    2: {
        "counter": true,
        "table": true,
        "customerName": false,
        "orderReadyTime": false
    },
    6: {
        "counter": false,
        "table": false,
        "customerName": true,
        "orderReadyTime": true
    },
    7: {
        "counter": true,
        "table": true,
        "customerName": false,
        "orderReadyTime": false
    }
}