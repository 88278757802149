import { createStore, applyMiddleware, Middleware } from "redux";
import rootReducer from "./reducers";
import thunk, { ThunkAction } from 'redux-thunk';
import websocketMiddleware from "./websocketMiddleware";

// This extension enables us to dispatch thunk actions 
declare module 'redux' {
  interface Dispatch<A extends Action = AnyAction> {
    <S, E, R>(asyncAction: ThunkAction<R, S, E, A>): R;
  }
}

const middleware = [
    thunk,
    websocketMiddleware
]

export const store = createStore(
    rootReducer, 
    applyMiddleware(...middleware as Middleware[])
);

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof rootReducer> // ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch