import React, { useEffect } from 'react';
import { Route, Switch } from 'react-router-dom'

import Main from './views/Main';
import Callback from './views/Callback';
import Silent from './views/Silent';

export default function App() {

	useEffect(() => {
		var lockResolver: any;
		var windowNavigator = navigator as any;
		if (windowNavigator && windowNavigator.locks && windowNavigator.locks.request) {
			const promise = new Promise((res) => {
				lockResolver = res;
			});
		
			windowNavigator.locks.request('crunchorder_queue_kds', { mode: "shared" }, () => {
				return promise;
			});
		}
		return () => {
			if (lockResolver) {
				lockResolver();
			}
		}
	}, []);

  	return (
	    <Switch>
			<Route exact path={"/"} component={Main} />
			<Route exact path={"/callback"} component={Callback} />
			<Route exact path={"/silent"} component={Silent} />
	    </Switch>
  	)
}