import React, { useEffect, useState, useMemo } from 'react';

import moment from 'moment';

import { useAppSelector } from '../../redux/hooks';

import Order from './Order';

import { addStateToOrders, filterOrderList } from '../../functions/actions/orders';
import { kdsStates } from '../../settings/app';

import { IOrder } from '../../interfaces/IOrder';
import { IFilter } from '../../interfaces/IFilter';

// Use values from filter + sort by orderReadyTime (only for queue orders)
const filteredQueueOrders = (orders: Array<IOrder>, filter: IFilter) => {
    const filtered = filterOrderList(orders.filter((o: IOrder) => o.stateId === kdsStates.QUEUE), filter);
    const sortedByOrderPlacedTime = filtered.sort((a: IOrder, b: IOrder) => moment(a.orderTimeUtc).valueOf() - moment(b.orderTimeUtc).valueOf());
    const sortedByOrderReadyTime = sortedByOrderPlacedTime.sort((a: IOrder, b: IOrder) => moment(a.orderReadyTime).valueOf() - moment(b.orderReadyTime).valueOf());
    return sortedByOrderReadyTime;
}
const filteredProductionOrders = (orders: Array<IOrder>, filter: IFilter) => filterOrderList(orders.filter((o: IOrder) => o.stateId === kdsStates.PRODUCTION)).sort((a: IOrder, b: IOrder) => moment(b.lastModifiedUtc).valueOf() - moment(a.lastModifiedUtc).valueOf());

export default function OrderList() {
    const { orders, filter, countInProduction } = useAppSelector((state) => state); // Including countInProduction to force a re-render when an order moves to production 
    
    var ordersWithStates = useMemo(() => {
        return addStateToOrders(orders); // Adding states to orders to avoid looping through multiple times 
    },[orders])

    var [queueOrdersToShow, setQueueOrdersToShow] = useState(filteredQueueOrders(ordersWithStates, filter));
    var [productionOrdersToShow, setProductionOrdersToShow] = useState(filteredProductionOrders(ordersWithStates, filter));

    useEffect(() => {

        function updateOrderlist(ordersWithStates: Array<IOrder>, filter: IFilter) { 
            setQueueOrdersToShow(filteredQueueOrders(ordersWithStates, filter));
            setProductionOrdersToShow(filteredProductionOrders(ordersWithStates, filter));
        }

        updateOrderlist(ordersWithStates, filter);

        var orderTimeInterval = setInterval(() => updateOrderlist(ordersWithStates, filter), 1000 * 60); // Filtering orders every minute to see if any new ones match our time filter 

        return () => { clearInterval(orderTimeInterval); }
    }, [ordersWithStates, filter, countInProduction]);

    return (
        <div className="order-list list">

            { queueOrdersToShow && queueOrdersToShow.map(function(order: IOrder) {
                return <Order 
                            key={order.id} 
                            order={order} 
                        />
            })}

            { filter.showRunOrders && countInProduction > 0 && productionOrdersToShow && productionOrdersToShow.map(function(order: IOrder) {
                return <Order 
                            key={order.id} 
                            order={order} 
                        />
            })}
            
        </div>
    )
}