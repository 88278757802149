import React from 'react';

import Ingredient from './Ingredient';

import { IIngredient } from '../../../interfaces/IIngredient';

// Showing the list of order elements as summarized ingredients 
export default function IngredientList(props: { ingredients: Array<IIngredient>, elementIdsToRun: Array<number>, checkedChange: (value: boolean, ids: Array<number>) => void }) { 
    const { ingredients } = props;

    const sortedIngredients = ingredients ? ingredients.sort((a, b) => a.sortOrder && b.sortOrder && a.sortOrder < b.sortOrder ? -1 : 1) : []; // Sorting alphabetically until we have sortOrder 

    return (
        <div className="order-body">

            { sortedIngredients.map(function(ingredient) {
                return <Ingredient 
                            key={ingredient.id} 
                            ingredient={ingredient} 
                            {...props}
                        />
            })}

        </div>
    )
}