import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';

import { Router } from 'react-router-dom';
import history from './history';

import './assets/index.scss';
import App from './App';

import * as serviceWorker from './serviceWorker';

// eslint-disable-next-line
import i18n from "./i18n"; // HAS to be imported 

import { Provider } from 'react-redux'
import { store } from './redux/store'

// require('dotenv').config();

ReactDOM.render(
	<Provider store={store}>
		<Suspense fallback="Loading">
			<Router history={history}>
				<App />
			</Router>
		</Suspense>
	</Provider>,
	document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();