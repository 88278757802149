import React from 'react';

import ElementChild from './ElementChild';
import { IElement } from '../../../../interfaces/IElement';

export default function Element(props: { element: IElement, elementIdsToRun: Array<number>, checkedChange: (value: boolean, ids: Array<number>) => void }) {
    const { element } = props;

    return (
        <div className="element">
            <p className="element-name">{element.amount} x {element.externalName}</p>

            { element.children && element.children.map(function(elementChild) {
                return <ElementChild key={elementChild.id} elementChild={elementChild}  {...props} />
            })}
        </div>
    )
}