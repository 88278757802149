import React from 'react';
import { useTranslation } from 'react-i18next';

import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete'

import { styled } from '@mui/material/styles';

import { moduleTypesList } from '../../settings/modules';
import { autoCompleteStyles } from '../../functions/mui';

import { IModule } from '../../interfaces/IModule';

const StyledAutocomplete = styled(Autocomplete)(autoCompleteStyles);

export default function Modules(props: { filterModuleIds: Array<string>, modules: Array<IModule>, handleFilterChange: (type: string, value: any) => void }) {
    const { filterModuleIds, modules } = props;
    const { handleFilterChange } = props;

    const { t } = useTranslation();

    // Getting full module objects from the ids in the filter
    var selectedModules = [];
    var i = 0;
    while (i < filterModuleIds.length) {
        const moduleId = filterModuleIds[i];
        const selectedModule = modules.find(m => m.id === moduleId);
        if (selectedModule) { selectedModules.push(selectedModule); }
        i++;
    }

    //const moduleLabel = (moduleType: number) => t(moduleTypesList.find(module => module.type === moduleType).translationKey);
    function moduleLabel(moduleType: number) {
        var label = "";
        const moduleTypeFound = moduleTypesList.find(module => module.type === moduleType);
        if (moduleTypeFound) { label = t(moduleTypeFound.translationKey); }
        return label;
    }

    return (
        <div className="module-selector-section filter-item">
            <StyledAutocomplete
                id="autocomplete-modules"
                className="autocomplete-modules"

                multiple
                filterSelectedOptions
                options={modules}
                value={selectedModules}
                getOptionLabel={(option: any) => option.name ? option.name : moduleLabel(option.type)}
                onChange={(event, newValue: any) => handleFilterChange("modules", newValue ? newValue.map((m: IModule) => m.id) : [])}
                renderInput={(params) => <TextField {...params} label={t("header:Modules")} variant="outlined" />}
                noOptionsText={t("common:NoOptions")}
            />
        </div>
    )
}