import React, { useState } from 'react';

import Checkbox from '../../Checkbox';

import { IIngredient } from '../../../interfaces/IIngredient';

import { kdsStates } from '../../../settings/app';

export default function Ingredient(props: { ingredient: IIngredient, checkedChange: (value: boolean, ids: Array<number>) => void }) {
    const { ingredient } = props;
    const { checkedChange } = props;

    var [isChecked, setIsChecked] = useState(false);
    var isInProduction = ingredient.state && ingredient.state.value === kdsStates.PRODUCTION ? true : false; // To overrule the checked state when all elements are run at once 

    function handleIngredientClick() {
        var updatedValue = !isChecked;

        var idsToUpdate = ingredient.ids ? ingredient.ids : [ingredient.id];

        setIsChecked(updatedValue);
        checkedChange(updatedValue, idsToUpdate);
    }

    return (
        <div className="order-element-container" onClick={handleIngredientClick}>

            <Checkbox isChecked={isChecked || isInProduction} isActive={!ingredient.state || ingredient.state.value === kdsStates.QUEUE} />

            <div className="element">
                <p className="element-name">{ingredient.amount} {ingredient.measurementUnit} x {ingredient.externalName}</p>
            </div>

        </div>
    )
}