import moment from "moment";

import { kdsStates } from '../../settings/app';

import { IElement } from "../../interfaces/IElement";
import { IFilter } from "../../interfaces/IFilter";
import { IIngredient } from "../../interfaces/IIngredient";
import { IOrder } from "../../interfaces/IOrder";

export function playNotificationSound() {
    try {
  
        var audio = new Audio('https://billedarkiv.blob.core.windows.net/videos/notification.mp3');
        audio.load();
        audio.play().then().catch(function(error) {
            console.log(error);
        });
  
      } catch(error) {
          console.log(error);
      }
}

export function getTimestampFromMilliseconds(s: number) { // Converting the millisecond diff to a timestamp
    var ms = s % 1000;
    s = (s - ms) / 1000;
    var secs = s % 60;
    s = (s - secs) / 60;
    var mins = s % 60;
    var hrs = (s - mins) / 60;
  
    function doubleDigits(n: number) {
        return n.toString().length === 1 ? "0" + n : n;
    }

    return doubleDigits(hrs) + ':' + doubleDigits(mins) + ':' + doubleDigits(secs);
}

function filterForTime(orders: Array<IOrder>, minutesAhead: number) {
    var filteredOrders = orders;
    var timeToFilterFor = moment().add(minutesAhead, "minutes");

    function filtering(order: IOrder) {
        const orderReadyMoment = moment(order.orderReadyTime);
        const isBeforeFilterTime = orderReadyMoment.isSameOrBefore(timeToFilterFor);
        const isToday = orderReadyMoment.isSame(moment(), 'day');

        return isBeforeFilterTime && isToday;
    }

    // Filtering orders before today or after the filter time 
    filteredOrders = orders.filter(order => filtering(order));

    return filteredOrders;
}

function filterForModules(orders: Array<IOrder>, moduleIds: Array<string>) {
    var filteredOrders = orders;

    function filtering(order: IOrder) {
        const orderModuleId = order.moduleId;
        return !orderModuleId || moduleIds.indexOf(orderModuleId) > -1;
    }

    // Filtering for the chosen modules
    filteredOrders = orders.filter(order => filtering(order));

    return filteredOrders;
}

export function filterOrderList(orders: Array<IOrder>, filterOptions?: IFilter) {
    var filteredOrders = orders;

    if (filterOptions) {
        if (filterOptions.minutesAhead) {
            filteredOrders = filterForTime(filteredOrders, filterOptions.minutesAhead);
        }
        if (filterOptions.modules && filterOptions.modules.length > 0) { // If no modules, we show all orders
            filteredOrders = filterForModules(filteredOrders, filterOptions.modules);
        }
    }

    return filteredOrders;
}

export const summarizedIngredients = (elements: Array<IElement>) => { 
    var summarized: Array<IIngredient> = [];

    const summarize = (ingredients: Array<IIngredient>) => {
        return ingredients.reduce((accumulatedList, eachIngredient) => {
            const indexOfIngredientInSummaryList = accumulatedList.findIndex((eachSummarizedIngredient) => eachSummarizedIngredient.externalId === eachIngredient.externalId);
            if (indexOfIngredientInSummaryList === -1) {
                accumulatedList.push(eachIngredient);
            } else if (!accumulatedList[indexOfIngredientInSummaryList].summarize) { // Ingredient is already in the array, but we cannot summarize it 
                accumulatedList.push(eachIngredient);
            } else { // Ingredient already exists in the array, so add the amounts 
                var updatedIngredient = Object.assign({}, accumulatedList[indexOfIngredientInSummaryList]);
                updatedIngredient.amount = updatedIngredient.amount + eachIngredient.amount;

                if (updatedIngredient.ids) {
                    updatedIngredient.ids.push(eachIngredient.id);
                } else {
                    updatedIngredient.ids = [updatedIngredient.id, eachIngredient.id];
                }

                accumulatedList[indexOfIngredientInSummaryList] = updatedIngredient;
            }
            return accumulatedList;
        }, [...summarized]); // initialize the new Array with the contents of summarize
    }

    const ingredientList = (elements: Array<IElement>) => {
        var list: Array<IIngredient> = [];

        function generateIngredientList(elements: Array<IElement>) {
            elements.forEach(element => {
                const { ingredients, children } = element;
                if (children && children.length) { 
                    generateIngredientList(children); 
                }
                if (ingredients && ingredients.length) { list = list.concat(ingredients); }
            });
        }

        generateIngredientList(elements);
        return list;
    }

    const finalIngredientList: Array<IIngredient> = ingredientList(elements);
    summarized = summarize(finalIngredientList);
    return summarized;
}

export const getElementIds = (elements: Array<IElement>) => { // Incl products and nested choices 
    var ids: Array<number> = [];
    var inQueue: Array<number> = [];
    var inProduction: Array<number> = [];

    const addElementIds = (elements: Array<IElement>) => {
        elements.forEach(function(element) {

            if (element.state) { // No state means it's a modifier 
                ids.push(element.id);

                if (element.state.value === kdsStates.QUEUE) { inQueue.push(element.id); }
                if (element.state.value === kdsStates.PRODUCTION) { inProduction.push(element.id); }
            } 

            if (element.children) { addElementIds(element.children); }
        });
    }

    addElementIds(elements);
    return { ids, inQueue, inProduction };
}

export const addStateToOrders = (orders: Array<IOrder>) => { // Setting the state for the whole order based on element states
    var ordersWithStates: Array<IOrder> = [];

    if (orders) {
        orders.forEach(function(order) {
            var orderWithState = Object.assign({}, order);

            const orderElementIds = getElementIds(order.elements);
            var elementIdsInQueue = orderElementIds.inQueue;
            var elementIdsInProduction = orderElementIds.inProduction; 

            const stateId = elementIdsInProduction.length === orderElementIds.ids.length ? kdsStates.PRODUCTION : elementIdsInQueue.length ? kdsStates.QUEUE : undefined;
            orderWithState.stateId = stateId;
            ordersWithStates.push(orderWithState);
        });
    }

    return ordersWithStates;
}