import React, { useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';

import { useAppSelector, useAppDispatch } from '../redux/hooks';
import { connectToWebsocket, setLoading } from '../redux/actions';

import { IInstance } from '../interfaces/IInstance';

import { authService, redirectAuth } from '../functions/auth';

import Header from '../components/Header';
import OrderList from '../components/Orders/OrderList';
import InstanceSelector from '../components/InstanceSelector';
import Spinner from '../components/Spinner';

import { getKDSInstances, setupDefaultLanguage } from '../functions/actions/startup';
import { updateConnectedInstance } from '../redux/thunks';

export default function Main() {
  const { loading, instance, orders } = useAppSelector((state) => state);

  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  var [authenticated, setAuthenticed] = useState(false);
  var [customerName, setCustomerName] = useState("");
  var [instances, setInstances] = useState([]);

  const unauthorizedError = t("common:UnauthorizedError");
  const genericError = t("common:Error");

  useEffect(() => {

    async function initialize() { // After login successful 
      dispatch(setLoading(true));
      try {
        // Getting the default language to use 
        setupDefaultLanguage();
  
        // Get KDS data 
        const instanceList = await getKDSInstances();
        if (instanceList && instanceList.error === undefined) {
          
          var updatedInstance;
          if (instanceList.length === 1) { 
            updatedInstance = instanceList[0];
          }

          dispatch(connectToWebsocket(updatedInstance)); // Sets the instance if it was already chosen, to avoid setting the instance before we're connected
          setInstances(instanceList);
          
        } else if (instanceList && instanceList.error === 401) {
          alert(unauthorizedError);
          redirectAuth(authenticated);
        } else {
          alert(genericError);
        }
      } catch(error) {
          console.log(error);
      } finally {
        dispatch(setLoading(false));
      }
    }

    async function setupUserManager() {
      await authService.initAuthService();
      const user = await authService.getUser();
      if (user) {
        setAuthenticed(true);
        setCustomerName(user.profile.organisation);

        initialize();
      }
    }

    setupUserManager();
    
    return () => {};

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, unauthorizedError]);

  function handleInstanceClick(updatedInstance: IInstance | undefined) {

    // Set instance on connection 
    dispatch(updateConnectedInstance(updatedInstance));

  }

  return (
    <div className="main page">

        <Header
          authenticated={authenticated}
          customerName={customerName} 
          
          handleInstanceClick={() => handleInstanceClick(undefined)}
        />

        { !instance && instances && instances.length > 0 &&
          <InstanceSelector instances={instances} handleInstanceClick={handleInstanceClick} />
        }

        { instance && orders && orders.length > 0 && 
          <OrderList /> 
        }

        <p className="mobile-error">{t("common:MobileError")}</p>

        { loading && <Spinner /> }

    </div> 
  )
}