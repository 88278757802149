import React, { useState } from 'react';

import Checkbox from '../../../Checkbox';
import Element from '../Elements/Element';

import { IElement } from '../../../../interfaces/IElement';

import { kdsStates } from '../../../../settings/app';
import { getElementIds } from '../../../../functions/actions/orders';

export default function Product(props: { element: IElement, elementIdsToRun: Array<number>, checkedChange: (value: boolean, ids: Array<number>) => void }) {
    const { element } = props;
    const { checkedChange } = props;

    var [isChecked, setIsChecked] = useState(false);
    var isInProduction = element.state && element.state.value === kdsStates.PRODUCTION ? true : false; // To overrule the checked state when all elements are run at once 

    function handleOrderElementClick() {
        var updatedValue = !isChecked;

        var allIds = getElementIds([element]);
        var idsToUpdate = allIds.inQueue;

        setIsChecked(updatedValue);
        checkedChange(updatedValue, idsToUpdate);
    }

    return (
        <div className="order-element-container" onClick={handleOrderElementClick}>

            <Checkbox isChecked={isChecked || isInProduction} isActive={!element.state || element.state.value === kdsStates.QUEUE} />

            <Element {...props} />

        </div>
    )
}