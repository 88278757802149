import React, { useEffect } from 'react';
import { useHistory } from "react-router-dom";

import { UserManager } from "oidc-client";
import { authService } from '../functions/auth';

export default function Callback() {
  let history = useHistory();

  useEffect(() => {

    var mgr = new UserManager({ loadUserInfo: true, filterProtocolClaims: true, response_mode:"query" }); // Initializing oidc manager with new parameters
    mgr.signinRedirectCallback().then(function(user) {
      if (user) { 
        authService.updateBearerToken(user.access_token);
        history.push('/');
      }
    }).catch(function(error) {
      console.log(error);
      history.push('/');
    });

  }, [history]);

  return (
    <div className="callback"></div>
  )

}