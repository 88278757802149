import type { AppDispatch } from './store'

import { setLoading, setInstance, setFilter, loadModules, loadOrders } from '../redux/actions';

import { IInstance } from '../interfaces/IInstance';

export function updateConnectedInstance(instance: IInstance | undefined) { // Updating data when instance changes - always assumes we're connected to SignalR

    return async (dispatch: AppDispatch, getState: any) => {

        // This will reset data, so we do it first 
        dispatch(setInstance(instance));

        var updatedFilter = Object.assign({}, getState().filter);
        var filterModules: Array<String> = []; 

        if (instance) {

            dispatch(setLoading(true));

            // Showing all this instance's modules by default 
            filterModules = instance.settings.modules;
        
            // Get the available modules, if any
            dispatch(loadModules());
        
            // Get initial orders 
            dispatch(loadOrders(instance.id));
        
            dispatch(setLoading(false));
        }

        updatedFilter.modules = filterModules;
        dispatch(setFilter(updatedFilter));

        return true;
    }
}